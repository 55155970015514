import React, { useEffect, useRef } from "react";

const Captcha = ({ code, onClick }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    // 清空画布
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // 设置背景
    ctx.fillStyle = "#f0f0f0";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // 绘制文字
    ctx.fillStyle = "#333";
    ctx.font = "24px Arial";
    ctx.textBaseline = "middle";
    ctx.textAlign = "center";

    // 随机旋转每个字符
    for (let i = 0; i < code.length; i++) {
      const x = 20 + i * 20;
      const y = canvas.height / 2;
      ctx.save();
      ctx.translate(x, y);
      ctx.rotate((Math.random() - 0.5) * 0.4);
      ctx.fillText(code[i], 0, 0);
      ctx.restore();
    }

    // 添加干扰线
    for (let i = 0; i < 3; i++) {
      ctx.beginPath();
      ctx.moveTo(Math.random() * canvas.width, Math.random() * canvas.height);
      ctx.lineTo(Math.random() * canvas.width, Math.random() * canvas.height);
      ctx.strokeStyle = `rgba(0,0,0,0.2)`;
      ctx.stroke();
    }
  }, [code]);

  return (
    <canvas
      ref={canvasRef}
      width={100}
      height={40}
      onClick={onClick}
      style={{ cursor: "pointer" }}
    />
  );
};

export default Captcha;
