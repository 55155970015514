import React, { useRef } from "react";
import classes from "./MainHome.module.css";
import { Button, Typography, message } from "antd";
const images = require.context("../assets/三国风插画", false, /\.webp$/);
const 插画 = images.keys().reduce((allImages, path) => {
  // 移除路径和扩展名，只保留文件名
  const key = path
    .replace(/^.*[\\\/]/, "")
    .split(".")
    .slice(0, -1)
    .join(".");
  allImages[key] = images(path);
  return allImages;
}, {});
import { FullPage, Slide } from "react-full-page";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSaleSmartly } from "../Client/utils";
import "react-lazy-load-image-component/src/effects/blur.css";

import Footer from "../Components/footer";

/**
 * # 美观的三国风电脑主页
 *
 * - 全屏滚动
 * - 图片懒加载
 * -
 */
function 电脑端主页() {
  useSaleSmartly();
  const fullPageRef = useRef(null);

  const handleSlideClick = (slideIndex) => {
    // 使用fullPageApi来改变Slide
    fullPageRef.current.scrollToSlide(slideIndex);
  };

  return (
    <FullPage
      controlsProps={{ className: classes.mainhome }}
      className={classes.mainhome}
      ref={fullPageRef}
    >
      <Slide>
        <section id={classes.hero_nav}>
          <nav id={classes.导航}>
            {/* <div className={classes.logo} > */}
            {/* <img src={插画.logo} alt="曹操" /> */}
            {/* <h1>曹操</h1> */}

            <a
              className={classes.navHref}
              onClick={() => {
                handleSlideClick(2);
              }}
              // href="#三大步骤"
            >
              如何开始
            </a>
            <a
              className={classes.navHref}
              onClick={() => {
                handleSlideClick(3);
              }}
              // href="#六大优势"
            >
              六大优势
            </a>
            <a
              className={classes.navHref}
              // href="#豪杰之选"
              onClick={() => {
                handleSlideClick(6);
              }}
            >
              豪杰之选
            </a>
            <a
              className={classes.navHref}
              // href="#客户反馈"
              onClick={() => {
                handleSlideClick(7);
              }}
            >
              客户反馈
            </a>
            <a className={classes.navHref} href="upload" target="_black">
              立即开始
            </a>
            <a
              className={classes.navHref}
              // onClick={() => {
              //   message.success("正在开发中...");
              // }}
              href="client"
              target="_black"
            >
              查看进度
            </a>
            {/* <a className={classes.navHref} href="https://www.caocao-wang.com">
              Sign Up
            </a> */}
          </nav>
          <div className={classes.hero}>
            <img src={插画.用户反馈_腾起的马} id={classes.hero腾起的马} />

            <div id={classes.hero右侧}>
              <h1 id={classes.heroTitle}>曹操wifi</h1>
              <div className={classes.btns}>
                <a id={classes.main_button} href="upload" target="_black">
                  立即开始
                </a>
                <a id={classes.aside_button} href="client" target="_black">
                  查看进度
                </a>
              </div>
            </div>
          </div>
        </section>
      </Slide>

      {/* <div className={classes.mainhome}> */}
      <Slide>
        <section data_description="显卡工作站" id={classes.显卡工作站}>
          <LazyLoadImage
            offset={200}
            id={classes.显卡工作站_三兵马}
            src={插画.显卡工作站_三兵马}
            alt="显卡工作站_三兵马"
          />
          <LazyLoadImage
            offset={200}
            id={classes.显卡工作站_兵马}
            src={插画.显卡工作站_兵马}
            alt="显卡工作站_兵马"
          />
          <div id={classes.显卡工作站渐变}>
            <div id={classes.显卡工作站文字}>
              <h2 id={classes.显卡工作站标题}>显卡工作站在线</h2>
              <h3 className={classes.generate_h3_title}>
                为您提供强大的密码强度测试服务
              </h3>
            </div>
            <LazyLoadImage
              offset={200}
              id={classes.显卡工作站_海浪}
              src={插画.显卡工作站_海浪}
              alt="显卡工作站"
            />
          </div>
        </section>
      </Slide>
      <Slide id="三大步骤">
        <section data_description="三大步骤" id={classes.三大步骤}>
          <LazyLoadImage
            offset={200}
            id={classes.三大步骤_红龙}
            src={插画.三大步骤_红龙}
            alt="三大步骤_红龙"
          />
          <div id={classes.步骤s}>
            <div className={classes.步骤}>
              <h3>在线解析</h3>
              <p>网站支持在线解析cap、hccapx、hc22000、pcapng格式</p>
            </div>

            <div className={classes.步骤}>
              <h3>透明执行</h3>
              <p>透明执行 显卡工作站随时报告执行状态，过程透明受监督。</p>
            </div>

            <div className={classes.步骤}>
              <h3>密码强度测试</h3>
              <p>
                密码强度测试，根据密码复杂度、长度、字符类型等参数，给出密码强度评分。
              </p>
            </div>
          </div>
        </section>
      </Slide>

      {/* <section data-description="六大优势" id={classes.六大优势}> */}

      {/* <h3 className={classes.六大优势_h3_title}>六大优势</h3> */}

      <Slide id="六大优势">
        <div className={`${classes.优势} ${classes.优势1}`}>
          <div className={classes.优势文案}>
            {/* <h3>AI</h3>
             */}

            <LazyLoadImage
              offset={200}
              id={classes.六大优势_AI}
              src={插画.六大优势_AI}
              alt="六大优势_AI"
            />
            <p>使用AI对话模型，根据信息自动生成掩码，辅助哈希运算。</p>
          </div>

          <div className={classes.优势文案}>
            {/* <h3>性能</h3>
             */}
            <LazyLoadImage
              offset={200}
              id={classes.六大优势_性能}
              src={插画.六大优势_性能}
              alt="六大优势_性能"
            />
            <p>基于显卡工作站运算哈希，速度快，效率高。</p>
          </div>

          <LazyLoadImage
            offset={200}
            id={classes.六大优势_剑客}
            src={插画.六大优势_剑客}
            alt="六大优势_剑客"
          />
        </div>
      </Slide>

      <Slide>
        <div className={`${classes.优势} ${classes.优势2}`}>
          <LazyLoadImage
            offset={200}
            id={classes.六大优势_兵马}
            src={插画.六大优势_兵马}
            alt="六大优势_兵马"
          />
          <div className={classes.优势文案}>
            {/* <h3>定制化</h3> */}

            <LazyLoadImage
              offset={200}
              id={classes.六大优势_定制化}
              src={插画.六大优势_定制化}
              alt="六大优势_定制化"
            />
            <p>
              定制化服务，顽强不息，根据你的信息，只要你不放弃，我们奉陪到底
            </p>
          </div>

          <div className={classes.优势文案}>
            {/* <h3>秘籍</h3> */}
            <LazyLoadImage
              offset={200}
              id={classes.六大优势_秘籍}
              src={插画.六大优势_秘籍}
              alt="六大优势_秘籍"
            />
            <p>
              独门秘籍，基于平台数据与语料库自行制作，友商跑不出，我们这里可以。
            </p>
          </div>
        </div>
      </Slide>

      <Slide>
        <div className={`${classes.优势} ${classes.优势3}`}>
          <LazyLoadImage
            offset={200}
            id={classes.六大优势_凤凰}
            src={插画.六大优势_凤凰}
            alt="六大优势_凤凰"
          />
          <div className={classes.优势文案}>
            {/* <h3>人性化</h3> */}
            <LazyLoadImage
              offset={200}
              id={classes.六大优势_人性化}
              src={插画.六大优势_人性化}
              alt="六大优势_人性化"
            />
            <p>人性化服务，客服耐心负责，跑出的密码有问题售后到底</p>
          </div>
          <div className={classes.优势文案}>
            {/* <h3>平等</h3> */}
            <LazyLoadImage
              offset={200}
              id={classes.六大优势_平等}
              src={插画.六大优势_平等}
              alt="六大优势_平等"
            />
            <p>杜绝哈希歧视，无论光猫，金刚，别人解决不了，都可以交给我们。</p>
          </div>
        </div>
      </Slide>
      {/* </section> */}

      <Slide id="豪杰之选">
        <section data-description="五个商品" id={classes.五个商品}>
          <h3 className={classes.generate_h3_title}>豪杰之选</h3>
          <div id={classes.商品s}>
            <商品
              商品名="菜鸟包"
              商品图片={
                <LazyLoadImage
                  offset={200}
                  className={classes.宝物}
                  src={插画.商品一}
                />
              }
              电费={0}
              费用={15}
              成功率={15}
              字典={"菜鸟字典"}
              实时进度={"❌"}
            />

            <商品
              商品名="基础包"
              商品图片={
                <LazyLoadImage
                  offset={200}
                  className={classes.宝物}
                  src={插画.商品二}
                />
              }
              电费={1}
              费用={20}
              成功率={25}
              字典={"基础70G"}
              实时进度={"✅"}
            />

            <商品
              商品名="进阶包"
              商品图片={
                <LazyLoadImage
                  offset={200}
                  className={classes.宝物}
                  src={插画.商品三}
                />
              }
              电费={2}
              费用={35}
              成功率={35}
              字典={"进阶100G"}
              实时进度={"✅"}
            />

            <商品
              商品名="金刚包"
              商品图片={
                <LazyLoadImage
                  offset={200}
                  className={classes.宝物}
                  src={插画.商品四}
                />
              }
              电费={5}
              费用={50}
              成功率={"??"}
              字典={"金刚字典"}
              实时进度={"✅"}
            />

            <商品
              商品名="定制包"
              商品图片={
                <LazyLoadImage
                  offset={200}
                  className={classes.宝物}
                  src={插画.商品五}
                />
              }
              电费={1}
              费用={20}
              成功率={70}
              字典={"定制任务"}
              实时进度={"✅"}
            />

            {/* 
          <div className={classes.商品}>
            <LazyLoadImage
        offset={200} id={classes.商品二} src={插画.商品二} />
            <LazyLoadImage
        offset={200} className={classes.商品格} src={插画.商品格} />
          </div>

          <div className={classes.商品}>
            <LazyLoadImage
        offset={200} id={classes.商品三} src={插画.商品三} />
            <LazyLoadImage
        offset={200} className={classes.商品格} src={插画.商品格} />
          </div>

          <div className={classes.商品}>
            <LazyLoadImage
        offset={200} id={classes.商品四} src={插画.商品四} />
            <LazyLoadImage
        offset={200} className={classes.商品格} src={插画.商品格} />
          </div>

          <div className={classes.商品}>
            <LazyLoadImage
        offset={200} id={classes.商品五} src={插画.商品五} />
            <LazyLoadImage
        offset={200} className={classes.商品格} src={插画.商品格} />
          </div> */}
          </div>
        </section>
      </Slide>

      {/* <section data-description="用户反馈" id={classes.用户反馈}> */}
      {/* <h3 id={classes.用户反馈标题}>用户反馈</h3> */}

      <Slide id="客户反馈">
        <div className={`${classes.反馈}`}>
          <LazyLoadImage
            offset={200}
            id={classes.用户反馈_腾起的马}
            src={插画.用户反馈_腾起的马}
          />

          <LazyLoadImage
            offset={200}
            id={classes.用户反馈_图像在左文本框}
            src={插画.用户反馈_图像在左文本框}
          />
          <div className={classes.反馈文案}>
            <h3 className={classes.generate_h3_title}>网络安全专业学生</h3>
            <p>
              网络安全专业学生：
              作为网安学生，我面对各种难题时，曹操Wifi真的给了很大帮助。他们的高性能工作站和专业团队响应快，解决问题的能力也超出预期。不管是啥文件格式，他们都能搞定，让我对导师的任务轻松应对。
            </p>
          </div>
        </div>
      </Slide>
      <Slide>
        <div className={`${classes.反馈} ${classes.反馈2}`}>
          <LazyLoadImage
            offset={200}
            id={classes.用户反馈_向左的士兵}
            src={插画.用户反馈_向左的士兵}
          />

          <LazyLoadImage
            offset={200}
            id={classes.用户反馈_图像在右文本框}
            src={插画.用户反馈_图像在右文本框}
          />
          <div className={classes.反馈文案}>
            <h3 className={classes.generate_h3_title}>网安专家</h3>
            <p>
              我提供了定制任务后，不到半小时我的密码就被攻克了，原来我的密码根本没有我想的那样安全，我以后不会再用自己的信息做密码了。
            </p>
          </div>
        </div>
      </Slide>
      <Slide>
        <div className={`${classes.反馈}`}>
          <LazyLoadImage
            offset={200}
            id={classes.用户反馈_向右的士兵}
            src={插画.用户反馈_向右的士兵}
          />
          <div className={classes.反馈文案}>
            <h3 className={classes.generate_h3_title}>打工族</h3>
            <p>
              我刚毕业，住进公寓后就找性价比高的哈希运算服务。曹操家的价格合适，又是成功了才收费，这种共担风险的模式太贴心了。他们的服务让我既省心又省钱地保护了网络安全。
            </p>
          </div>
          <LazyLoadImage
            offset={200}
            id={classes.用户反馈_图像在左文本框}
            src={插画.用户反馈_图像在左文本框}
          />
        </div>
      </Slide>
      {/* </section> */}

      {/* </div> */}

      <Slide>
        <Footer />
      </Slide>
    </FullPage>
  );
}

function 商品({ 商品名, 商品图片, 电费, 费用, 成功率, 字典, 实时进度 }) {
  return (
    <div className={classes.商品}>
      <h3>{商品名}</h3>
      {商品图片}
      <LazyLoadImage
        offset={200}
        className={classes.商品格}
        src={插画.商品格}
      />
      <ul>
        <li>电费:{电费}</li>
        <li>费用:{费用}</li>
        <li>成功率：{成功率}%</li>
        <li>字典：{字典}</li>
        <li>实时进度：{实时进度}</li>
      </ul>
      <button
        onClick={() => {
          window.open("/upload");
        }}
      >
        现在开始
      </button>
    </div>
  );
}

export default 电脑端主页;
